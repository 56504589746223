import { ref, computed, useContext, useStore, onMounted, reactive } from '@nuxtjs/composition-api'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { formatDate } from '@/utils/tool'
import { getCorpSelect } from '@/utils/auth'


export default {
  __name: 'homeDialog',
  setup(__props) {

const context = useContext()
const store = useStore()
const { loginCorpId, userInfo, corps } = useLoginInfo()
const { push } = useLink()

const localStorageKey = computed(() => {
  if (loginCorpId.value) {
    return loginCorpId.value
  } else if (userInfo.value) {
    return userInfo.value.optUserId
  }
})

// 已认证未上传文件
const incompleteVisible = ref(false)
const license = computed(() => {
  return store.getters.corpIdFile ? Number(!store.getters.corpIdFile?.license) : 0
})
const sqwts = computed(() => {
  return store.getters.corpIdFile ? (store.getters.corpIdFile?.sqwts == 2 ? 0 : 1) : 0
})

const corpStatus = computed(() => store.getters.corpIdFile?.status)

const incompleteInfo = computed(() => {
  const info = []
  if (license.value) {
    info.push('营业执照')
  }
  if (sqwts.value) {
    info.push('管理员授权委托书')
  }
  return info.join('、')
})

const onSubmitIncomplete = () => {
  incompleteVisible.value = false
  clearIncompleteStorage()
  push('/usercenter/company')
}

const clearIncompleteStorage = () => {
  store.commit('tipMsgDialog/SET_INCOMPLETEDIALOGVISIBLE', false)
  store.commit('tipMsgDialog/SET_CORPIDFILE', {})
  localStorage.removeItem(`corpIdFile-${loginCorpId.value}`)
}

const closeIncomplete = () => {
  incompleteVisible.value = false
  clearIncompleteStorage()
  if (overDelivery.value > 0) {
    overDeliveryVisible.value = true
  }
}

// 超提未补款
const overDeliveryVisible = ref(false)
const overDelivery = computed(() => store.getters.overDelivery)

const onSubmitOverDelivery = () => {
  closeOverDelivery()
  push('/buyercenter/deliveryOrder?type=1&status=9')
}

const closeOverDelivery = () => {
  overDeliveryVisible.value = false
  localStorage.removeItem(`overDelivery-${loginCorpId.value}`)
}

// 消息弹框
const validMsgVisible = ref(false)
const storeValidMsgVisible = computed(() => store.getters.validMsgVisible)
const readIds = ref([])
const homeTipList = computed(() => store.getters.homeTipList)
const currentWin = reactive({})
const currentDate = ref('')

const closeValidMsg2 = () => {
  validMsgVisible.value = false
}

const closeValidMsg = () => {
  saveReadId()
  showMsgDialog()
  if (homeTipList.value.length && homeTipList.value?.length == readIds.value?.length) {
    showNextDilaog()
  }
}

const saveReadId = () => {
  readIds.value.push(currentWin.id)
  localStorage.setItem(`win-readIds-${localStorageKey.value}`, JSON.stringify(readIds.value))
  if (homeTipList.value.length && homeTipList.value?.length == readIds.value?.length) {
    localStorage.setItem(`currentDate-${localStorageKey.value}`, formatDate(new Date(), 'yyyy-MM-dd'))
  }
}

const pushQuestion = () => {
  saveReadId()
  push(`/questionaire?id=${currentWin.id}`)
}

const winTypeMap = new Map([
  ['W001', '问卷弹窗'],
  ['W002', '消息弹窗']
])

const initStoreData = () => {
  const _overDelivery = +localStorage.getItem(`overDelivery-${loginCorpId.value}`) || 0
  const _corpIdFile = JSON.parse(localStorage.getItem(`corpIdFile-${loginCorpId.value}`)) || {}
  const _homeTipList = JSON.parse(localStorage.getItem(`win-${localStorageKey.value}`)) || []
  store.commit('tipMsgDialog/SET_OVERDELIVERY', _overDelivery)
  store.commit('tipMsgDialog/SET_CORPIDFILE', _corpIdFile)
  store.commit('tipMsgDialog/SET_HOMETIPLIST', _homeTipList)
}

const showNextDilaog = () => {
  if (corpStatus.value) {
    if (
      (sqwts.value > 0 || license.value > 0) &&
      loginCorpId.value &&
      !(homeTipList.value.length && homeTipList.value?.length != readIds.value?.length)
    ) {
      incompleteVisible.value = true
    }
  }
  if (
    overDelivery.value > 0 &&
    loginCorpId.value &&
    !sqwts.value &&
    !license.value &&
    !(homeTipList.value.length && homeTipList.value?.length != readIds.value?.length)
  ) {
    overDeliveryVisible.value = true
  }
}

const showMsgDialog = () => {
  if (!currentDate.value || formatDate(new Date(), 'yyyy-MM-dd') != formatDate(currentDate.value, 'yyyy-MM-dd')) {
    if (homeTipList.value?.length && homeTipList.value?.length != readIds.value?.length) {
      const noReadList = homeTipList.value.filter((_) => !readIds.value?.includes(_.id))
      Object.assign(currentWin, noReadList[0] ?? {})
      // showValidMsg()
      validMsgVisible.value = true
    }
  }
}

onMounted(async () => {
  currentDate.value = localStorage.getItem(`currentDate-${localStorageKey.value}`) || ''
  readIds.value = JSON.parse(localStorage.getItem(`win-readIds-${localStorageKey.value}`)) || []
  if (currentDate.value && formatDate(new Date(), 'yyyy-MM-dd') != formatDate(currentDate.value, 'yyyy-MM-dd') && readIds.value.length > 0) {
    localStorage.removeItem(`win-readIds-${localStorageKey.value}`)
    localStorage.removeItem(`win-${localStorageKey.value}`)
    readIds.value = []
  }

  if (getCorpSelect(context) || (!getCorpSelect(context) && corps.value.length == 0)) {
    const _homeTipList = JSON.parse(localStorage.getItem(`win-${localStorageKey.value}`)) || []
    if (
      userInfo.value &&
      !_homeTipList.length &&
      (!currentDate.value || formatDate(new Date(), 'yyyy-MM-dd') != formatDate(currentDate.value, 'yyyy-MM-dd'))
    ) {
      await store.dispatch('tipMsgDialog/GET_HOMEMSG', context)
      localStorage.setItem(`win-${localStorageKey.value}`, JSON.stringify(store.getters.homeTipList))
      initStoreData()
      showMsgDialog()
      showNextDilaog()
    } else {
      initStoreData()
      showMsgDialog()
      showNextDilaog()
    }
  }
})

return { __sfc: true,context, store, loginCorpId, userInfo, corps, push, localStorageKey, incompleteVisible, license, sqwts, corpStatus, incompleteInfo, onSubmitIncomplete, clearIncompleteStorage, closeIncomplete, overDeliveryVisible, overDelivery, onSubmitOverDelivery, closeOverDelivery, validMsgVisible, storeValidMsgVisible, readIds, homeTipList, currentWin, currentDate, closeValidMsg2, closeValidMsg, saveReadId, pushQuestion, winTypeMap, initStoreData, showNextDilaog, showMsgDialog }
}

}