import introduce from '@/components/home/introduce.vue'
import { useLink } from '@/hooks/useLink'


export default {
  __name: 'Introduce',
  setup(__props) {

const { pushBcs } = useLink()
const bottomText = [
  // '现货资源 尽在钢宝',
  '阳光采购 质美价优',
  '优质服务 专业可靠'
]

const links = [
  { name: '金陵钢宝网', link: 'https://www.gangbao365.com/' },
  { name: '新智造网', link: 'https://www.xzhizao.com/' }
]

return { __sfc: true,pushBcs, bottomText, links, introduce }
}

}