
import {
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
  useContext,
  useRoute,
  computed,
  onUnmounted,
  watch,
  inject,
  useMeta
} from '@nuxtjs/composition-api'
import countdown from '@/components/bid/countdown.vue'
import tipDialog from '@/components/spotMall/tipDialog.vue'
import { useLink } from '@/hooks/useLink'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { useSyncTime } from '@/hooks/useSyncTime'
import { formatNumber, formatDate } from '@/utils/tool'
import handleBatchDownload from '@/utils/download'
import { bidTypeMap, bidRefreshSecond, evaluationTypeMap } from '@/constant/bid'

export default defineComponent({
  components: {
    countdown,
    tipDialog
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const router = useRoute()
    const { push } = useLink()
    const auctionNo = computed(() => router.value.params.id)
    const { currentTime } = useSyncTime()
    const state = reactive({
      isSkeleton: true,
      detailInfo: {},
      startMonth: '',
      startDay: '',
      startTime: '',
      intervalTime: null,
      tipMessage: '',
      activeName: 'productInfo'
    })
    const { userInfo, loginCorpId, transportAuth } = useLoginInfo()
    const { judgeIsLogin, judgeIsCompany, judgeIsAuth, showErrorTip } = useMsgDialog()
    const columns = ref([
      {
        label: '标的物',
        prop: 'targetName',
        width: 120,
        render: (row) => {
          return <content-tooltip spanText={`${row.targetName}`} width={110} placement="bottom"></content-tooltip>
        }
      },
      {
        label: '规格',
        prop: 'targetSpec',
        width: 120,
        render: (row) => {
          return <content-tooltip spanText={`${row.targetSpec}`} width={110} placement="bottom"></content-tooltip>
        }
      },
      {
        label: '运输方式',
        prop: 'transMode',
        width: 120,
        render: (row) => {
          return <content-tooltip spanText={`${row.transMode}`} width={110} placement="bottom"></content-tooltip>
        }
      },
      {
        label: '运输重量',
        prop: 'transWeight',
        width: 120,
        render: (row) => {
          return <content-tooltip spanText={`${row.transWeight}`} width={110} placement="bottom"></content-tooltip>
        }
      },
      {
        label: '起运地',
        prop: 'origination',
        width: 180,
        render: (row) => {
          return <content-tooltip spanText={`${row.origination}`} width={170} placement="bottom"></content-tooltip>
        }
      },
      {
        label: '目的地',
        prop: 'destination',
        render: (row) => {
          return <content-tooltip spanText={`${row.destination}`} width={218} placement="bottom"></content-tooltip>
        }
      },
      {
        label: '运输时间',
        prop: 'transTime',
        width: 120,
        render: (row) => {
          return <content-tooltip spanText={`${row.transTime}`} width={110} placement="bottom"></content-tooltip>
        }
      },
      {
        label: '付款方式',
        prop: 'payMode',
        width: 80
      },
      {
        label: '报价税率',
        prop: 'taxRate',
        width: 110,
        render: (row) => {
          return <content-tooltip spanText={`${row.taxRate}`} width={100} placement="bottom"></content-tooltip>
        }
      }
    ])

    const tableData = ref([])

    const getTransportDetailAsync = async () => {
      const { statusCode, data } = await context.$api.transport.getTransportDetail({ auctionNo: auctionNo.value })
      if (statusCode == 200) {
        tableData.value = [data]
      }
    }

    const handleOffer = (row) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!transportAuth.value) {
        judgeIsAuth()
        return
      }
      push('/buyercenter/transport')
    }
    const confirmRef = ref(null)
    const $message = inject('$message')
    const handleJoin = () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!transportAuth.value) {
        judgeIsAuth()
        return
      }
      state.tipMessage = '是否参与该招标？'
      confirmRef.value.dialogVisible = true
    }
    const depositRef = ref(null)
    const tipRef = ref(null)
    const getDetailAsync = async () => {
      const { data, statusCode } = await context.$api.transport.getTransBidDetail({ auctionNo: auctionNo.value })
      if (statusCode == 200) {
        if (data) {
          state.isSkeleton = false
          state.detailInfo = data
          state.startMonth = data.bidStartTime ? formatDate(data.bidStartTime, 'MM') : ''
          state.startDay = data.bidStartTime ? formatDate(data.bidStartTime, 'dd') : ''
          state.startTime = data.bidStartTime ? formatDate(data.bidStartTime, 'hh:mm') : ''
        } else {
          tipRef.value.dialogVisible = true
        }
      }
    }
    useMeta(() => ({ title: state.detailInfo.auctionName }))
    const handleDownload = (type) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (type == 'file') {
        const fileData = state.detailInfo.files.map((_) => _.fileUrl)
        const fileDataName = state.detailInfo.files.map((_) => _.fileName)
        handleBatchDownload(fileData, '附件下载', false, fileDataName)
      }
    }

    const setTimer = () => {
      clearTimeout(state.intervalTime)
      state.intervalTime = setTimeout(() => {
        getTransportDetailAsync()
        getDetailAsync()
        setTimer()
      }, 1000 * bidRefreshSecond)
    }

    const sureTipConfirm = async () => {
      // 运输招标参加不需要输入密码操作，直接调用参加接口
      const { statusCode, message } = await context.$api.bid.joinAuction({
        auctionNo: state.detailInfo.auctionNo
      })
      if (statusCode == 200) {
        confirmRef.value.dialogVisible = false
        $message.success('操作成功')
        getDetailAsync()
      } else if (statusCode == 50030) {
        showErrorTip(message, 9)
      } else {
        $message.error(message)
      }
    }

    watch(
      () => currentTime.value,
      (newVal) => {
        const time = (newVal - 59 - 60 * 29) / 60 / 30
        if (typeof time === 'number' && time % 1 === 0) {
          getDetailAsync()
          setTimeout(() => {
            getDetailAsync()
          }, 1000 * 2)
        }
      }
    )

    watch(
      () => userInfo.value,
      () => {
        getDetailAsync()
      }
    )
    const handleVisiable = (e) => {
      const isHidden = e.target.hidden
      if (isHidden === false) {
        getTransportDetailAsync()
        getDetailAsync()
        setTimer()
      }
    }

    const handleChangeTab = ({ name }) => {
      state.activeName = name
    }

    onMounted(() => {
      getDetailAsync()
      getTransportDetailAsync()
      setTimer()
      document.addEventListener('visibilitychange', handleVisiable)
    })
    onUnmounted(() => {
      clearTimeout(state.intervalTime)
      document.removeEventListener('visibilitychange', handleVisiable)
    })

    return {
      ...toRefs(state),
      columns,
      tableData,
      handleOffer,
      handleJoin,
      depositRef,
      currentTime,
      bidTypeMap,
      getDetailAsync,
      userInfo,
      loginCorpId,
      handleDownload,
      judgeIsLogin,
      push,
      tipRef,
      confirmRef,
      sureTipConfirm,
      formatNumber,
      evaluationTypeMap,
      handleChangeTab
    }
  },
  head: {
    title: '运输招标详情-鑫智特钢网'
  }
})
