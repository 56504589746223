import { render, staticRenderFns } from "./introduce.vue?vue&type=template&id=1e106ce1"
import script from "./introduce.vue?vue&type=script&setup=true&lang=js"
export * from "./introduce.vue?vue&type=script&setup=true&lang=js"
import style0 from "./introduce.vue?vue&type=style&index=0&id=1e106ce1&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports