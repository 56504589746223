import { computed, useRoute } from '@nuxtjs/composition-api'


export default {
  __name: '_type',
  setup(__props) {

const typeMap = new Map([
  ['regist', '注册登录'],
  ['auth', '会员申请'],
  ['buy', '现货采购'],
  ['delivery', '预约提货'],
  ['bid', '竞价采购'],
  ['afterSale', '售后服务'],
  ['invoice', '发票管理']
])
const route = useRoute()
const paramsType = computed(() => route.value.params.type)

return { __sfc: true,typeMap, route, paramsType }
}

}