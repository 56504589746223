
import { defineComponent, reactive, ref, useContext } from '@nuxtjs/composition-api'
import tableHeaderButtons from '@/components/notice/tableHeaderButtons.vue'
import queryCondition from '@/components/notice/queryCondition.vue'
import { useExTable } from '@/hooks/useExTable'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { formatDate } from '@/utils/tool'
export default defineComponent({
  components: {
    queryCondition,
    tableHeaderButtons
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const { push } = useLink()
    const query = reactive({})
    const columns = ref([
      {
        slot: 'content'
      },
      {
        width: '200',
        render: (row) => {
          return <span class="date-color">{formatDate(row.pushTime, 'yyyy年MM月dd日 hh:mm')}</span>
        }
      }
    ])

    const { data, total, getListAsync, search, handleCurrentChange, handleSizeChange } = useExTable({
      query,
      queryApi: context.$api.home.getNoticeList,
      limit: 10
    })

    const doSearch = (data) => {
      const order = data.order == 1 ? 'asc' : data.order == 2 ? 'desc' : ''
      const orderField = data.order ? 'PUSH_TIME' : ''
      search({ order, orderField })
    }

    const { showErrorTip } = useMsgDialog()
    const handleRowClick = async (row) => {
      const { data, statusCode, message } = await context.$api.home.getNoticeDeatil({ id: row.id })
      if (statusCode == 200) {
        push(`/notice/${row.id}`)
      } else {
        showErrorTip(message)
        getListAsync()
      }
    }
    return {
      columns,
      query,
      data,
      total,
      handleCurrentChange,
      handleSizeChange,
      doSearch,
      search,
      handleRowClick
    }
  },
  head: {
    title: '公告-鑫智特钢网'
  }
})
