
import { computed, defineComponent, useRoute } from '@nuxtjs/composition-api'
import { useTitle } from '@/hooks/useTitle'
import cartLists from '~/components/shopCarts/cartLists.vue'
import orderLists from '~/components/shopCarts/orderLists.vue'
export default defineComponent({
  name: '',
  components: {
    cartLists,
    orderLists
  },
  layout: 'cart',
  setup() {
    useTitle('购物车')
    const route = useRoute()
    const currentView = computed(() => {
      if (route.value.query.type) {
        return 'orderLists'
      }
      return 'cartLists'
    })
    return {
      currentView
    }
  },
  head: {
    title: '购物车-鑫智特钢网'
  }
})
