
import { defineComponent } from '@nuxtjs/composition-api'
import goodsTypeMenus from '@/components/homeHeader/components/goodsTypeMenus.vue'
import centerAdvert from '@/components/home/centerAdvert.vue'
import centerRightInfo from '@/components/home/centerRightInfo.vue'
import dataStatistics from '@/components/home/dataStatistics.vue'
import originResource from '@/components/home/originResource.vue'
import recommend from '@/components/home/recommend.vue'
import bidZone from '@/components/home/bidZone.vue'
import industryInfo from '@/components/home/industryInfo.vue'
// import waistBanner from '@/components/home/waistBanner.vue'
import homeDialog from '~/components/layoutComp/homeDialog.vue'

export default defineComponent({
  name: '',
  components: {
    goodsTypeMenus,
    centerAdvert,
    centerRightInfo,
    dataStatistics,
    originResource,
    recommend,
    bidZone,
    industryInfo,
    // waistBanner,
    homeDialog
  },
  layout: 'home',
  head: {
    meta: [
      {
        hid: 'keywords',
        name: 'keywords',
        content: '鑫智特钢网,现货商城,单条竞价,打包竞价'
      }
    ]
  }
})
