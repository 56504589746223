
import { defineComponent, ref } from '@nuxtjs/composition-api'
import { JIN_MAO } from '@/constant'
import { useLink } from '@/hooks/useLink'
export default defineComponent({
  name: 'AppFooter',
  serverCacheKey: (key) => key,
  props: {
    useType: {
      type: String,
      default: 'normal'
    }
  },
  setup() {
    const links = ref([
      {
        text: '关于我们'
      },
      {
        text: '联系我们',
        url: '/helpcenter/contact/service'
      },
      {
        text: '帮助中心',
        url: '/helpcenter/trendRule'
      },
      {
        text: '工商执照',
        url: 'https://oss.tegang365.com/dp-public-prd/sqwts/license.jpg'
      }
    ])

    const icp = ref('https://beian.miit.gov.cn/#/Integrated/index')
    const licence = ref('')

    const { pushBcs } = useLink()
    return {
      links,
      icp,
      licence,
      JIN_MAO,
      pushBcs
    }
  }
})
