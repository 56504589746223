import { ref } from '@nuxtjs/composition-api'


export default {
  __name: 'internetbank',
  setup(__props) {

const inforMation = ref([
  { name: '收款人: ', content: '安徽宝景供应链管理有限公司' },
  { name: '公司开户行: ', content: '中国银行和县郑蒲港支行' },
  { name: '银行账号: ', content: '187269991170' },
  { name: '联行号: ', content: '104378505473' }
])

return { __sfc: true,inforMation }
}

}