import { ref } from '@nuxtjs/composition-api'
import { HOT_LINE } from '@/constant'


export default {
  __name: 'acceptPay',
  setup(__props) {

const info = ref([
  { name: '收款人: ', content: '安徽宝景供应链管理有限公司' },
  { name: '公司开户行: ', content: '中信银行马鞍山湖北路支行' },
  { name: '银行账号: ', content: '8112301012700888788' },
  { name: '联行号: ', content: '302365051615' }
])

return { __sfc: true,info, HOT_LINE }
}

}