import { reactive, toRefs } from '@nuxtjs/composition-api'
import serviceItem from '@/components/helpcenter/serviceItem.vue'
import { HOT_LINE } from '@/constant'
import { customers } from '@/constant/helpContact'


export default {
  __name: 'service',
  setup(__props, { expose }) {

const state = reactive({
  platformMobile1: '13865557606',
  platformMobile2: ''
})
expose({
  ...toRefs(state)
})

return { __sfc: true,state, serviceItem, HOT_LINE, customers }
}

}