import { ref, watch, useContext, inject } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { JIN_MAO_ID } from '@/constant'


export default {
  __name: 'warrantyTag',
  props: {
  item: {
    type: Object,
    default: () => {}
  },
  isBid: {
    type: Boolean,
    default: false
  },
  showType: {
    type: String,
    default: 'tag'
  }
},
  emits: 'quality',
  setup(__props, { emit }) {

const props = __props;



const context = useContext()
const { userInfo } = useLoginInfo()
const { judgeIsLogin } = useMsgDialog()
const $message = inject('$message')

const disbaled = ref(false)

const getDisabled = (row) => {
  return !userInfo.value || (!!userInfo.value && row.warranty == 3)
}


const handleQuality = async (row) => {
  if (!userInfo.value) {
    judgeIsLogin(props.isBid)
    return
  }
  if (row.warranty != 3) {
    return
  }
  const { statusCode, data } = await context.$api.goods.getWarranty({ resourceId: row.resourceId })
  if (statusCode == 200) {
    emit('quality', data)
  }
}

watch(
  () => props.item,
  () => {
    disbaled.value = getDisabled(props.item)
  },
  {
    immediate: true,
    deep: true
  }
)

return { __sfc: true,props, context, userInfo, judgeIsLogin, $message, disbaled, getDisabled, emit, handleQuality }
}

}