import { ref, reactive, nextTick } from '@nuxtjs/composition-api'
import UploadFile from './uploadFile.vue'
import { provideMaterialsMap, cuttingMap } from '@/constant/demand'
import { useLoginInfo } from '@/hooks/useLoginInfo'


export default {
  __name: 'demand',
  props: {
  info: {
    type: Object,
    default: () => {}
  }
},
  emits: ['save'],
  setup(__props, { expose, emit }) {

const props = __props;




const { userInfo, loginCorpId } = useLoginInfo()

const pickerOptions = {
  disabledDate(time) {
    return time.getTime() < Date.now() - 8.64e7
  }
}

const editFormRef = ref(null)
const obj = {
  provideMaterials: 0,
  cuttingNo: 0,
  sampleFileList: [],
  machiningDemandInfo: '',
  materialFileList: [],
  materialInfo: '',
  requiredCompletionDate: '',
  materialRequirements: '',
  originRequirements: '',
  areaName: []
}
const editForm = reactive({ ...obj })
const rules = {
  cuttingNo: [{ required: true, message: '请选择加工类型', trigger: 'change' }],
  provideMaterials: [{ required: true, message: '请选择平台提供材料', trigger: 'change' }],
  sampleFileList: [{ type: 'array', required: true, message: '请上传样品图', trigger: 'change' }],
  machiningDemandInfo: [{ required: true, message: '请输入加工要求', trigger: 'blur' }],
  materialFileList: [{ type: 'array', required: true, message: '请上传来料图', trigger: 'change' }],
  materialInfo: [{ required: true, message: '请输入来料信息', trigger: 'blur' }],
  requiredCompletionDate: [{ required: true, message: '请选择要求完工日期', trigger: 'change' }],
  areaName: [{ required: true, message: '请选择收货地区', trigger: 'change' }]
}

const handleSuccess1 = (response) => {
  editForm.sampleFileList = response
  editFormRef.value.validateField('sampleFileList')
}

const handleSuccess2 = (response) => {
  editForm.materialFileList = response
  editFormRef.value.validateField('materialFileList')
}

const validate = () => {
  return editFormRef.value.validate()
}

const unploadFile1 = ref(null)
const unploadFile2 = ref(null)
const resetFields = () => {
  unploadFile1.value && unploadFile1.value.handleBeforeRemove()
  unploadFile2.value && unploadFile2.value.handleBeforeRemove()
  Object.assign(editForm, { ...obj })
  editForm.materialFileList = []
  editFormRef.value.resetFields()
}

const handleChange2 = () => {
  nextTick(() => {
    if (unploadFile2.value) {
      unploadFile2.value.uploadRef.uploadFiles = editForm.materialFileList.map((_) => {
        return {
          ..._,
          status: 'success'
        }
      })
    }
  })
  handleLoginAuth()
}

const handleLoginAuth = () => {
  emit('save')
}

expose({
  validate,
  editForm,
  resetFields
})

return { __sfc: true,props, emit, userInfo, loginCorpId, pickerOptions, editFormRef, obj, editForm, rules, handleSuccess1, handleSuccess2, validate, unploadFile1, unploadFile2, resetFields, handleChange2, handleLoginAuth, UploadFile, provideMaterialsMap, cuttingMap }
}

}