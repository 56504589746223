import { onMounted, useContext, reactive, toRefs, onUnmounted, computed, useStore, watch } from '@nuxtjs/composition-api'
import { formatNumber, formatDate } from '@/utils/tool'
import { useInterval } from '@/hooks/useInterval'


export default {
  __name: 'dataStatistics',
  setup(__props, { expose }) {

const context = useContext()
const store = useStore()
const state = reactive({
  todayWeight: 0,
  timer: null,
  activeIndex: '0'
})
const chartOption = reactive({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line',
      z: 1,
      lineStyle: {
        type: 'solid',
        width: 2,
        color: '#101d377a'
      }
    },
    backgroundColor: '#101D37',
    textStyle: {
      color: 'white'
    },
    borderWidth: 0,
    borderRadius: [4],
    formatter(params) {
      console.log(params)
      let str = ''
      params.forEach((item) => {
        str += item.data.startDate + '-' + item.data.endDate + '</br>' + item.value
      })
      return str
    },
    extraCssText: 'box-shadow:0px 6px 30px 0px rgba(0, 0, 0, 0.25);'
  },
  title: {
    text: '平台成交量走势',
    subtext: '成交量（吨）',
    itemGap: 16,
    subtextStyle: {
      color: '#101D37'
    },
    fontSize: 20,
    color: '#101D37',
    padding: [22, 25]
  },
  grid: {
    top: '22%',
    left: '5%',
    bottom: 20,
    right: '5%',
    containLabel: true
  },
  xAxis: {
    name: '',
    nameTextStyle: {
      color: '#969CA7'
    },
    type: 'category',
    boundaryGap: false,
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      lineStyle: {
        width: 2,
        type: 'solid',
        color: '#e6e9eb'
      }
    },
    axisLabel: {
      color: '#969CA7'
    }
  },
  yAxis: {
    type: 'value',
    min: 1000,
    nameGap: 20,
    nameTextStyle: {
      color: '#101D37',
      align: 'middle',
      fontWeight: 'bold'
    },
    axisLine: {
      show: true,
      lineStyle: {
        width: 2,
        type: 'solid',
        color: '#e6e9eb'
      }
    },
    axisLabel: {
      color: '#969CA7',
      showMinLabel: false
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(151,151,151,0.2)',
        type: [5, 3]
      }
    }
    // max: 6000
  },
  series: [
    {
      name: '',
      data: [],
      type: 'line',
      smooth: true,
      showSymbol: false,
      itemStyle: {
        color: '#5073FF',
        emphasis: {
          color: '#fff',
          borderWidth: 2
        }
      },
      areaStyle: {
        color: '#5073FF '
      }
    }
  ],
  animationDuration: 5 * 1000,
  animationEasing: 'cubicOut'
})
const areaColor0 = {
  type: 'linear',
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [
    { offset: 0, color: '#ff87a7a6' },
    { offset: 1, color: '#ffffff96' }
  ]
}

const areaColor1 = {
  type: 'linear',
  x: 0,
  y: 0,
  x2: 0,
  y2: 1,
  colorStops: [
    { offset: 0, color: '#D0B7FF' },
    { offset: 1, color: '#FFFFFF96' }
  ]
}

const chartOption1 = reactive({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'line',
      z: 1,
      lineStyle: {
        type: 'solid',
        width: 2,
        color: '#101d377a'
      }
    },
    backgroundColor: '#101D37',
    textStyle: {
      color: 'white'
    },
    borderWidth: 0,
    borderRadius: [4],
    valueFormatter: (value) => '¥' + formatNumber(value),
    extraCssText: 'box-shadow:0px 6px 30px 0px rgba(0, 0, 0, 0.25);'
  },
  title: {
    text: '平台价格走势',
    subtext: '价格(元/吨)',
    itemGap: 16,
    subtextStyle: {
      color: '#101D37'
    },
    fontSize: 20,
    color: '#101D37',
    padding: [22, 25]
  },
  legend: {
    itemWidth: 10,
    itemHeight: 10,
    right: 20,
    top: 20,
    padding: [11, 12],
    data: [
      {
        icon: 'circle',
        name: '圆钢'
      },
      {
        icon: 'circle',
        name: '优特钢'
      }
    ],
    selectedMode: false,
    borderRadius: [21],
    backgroundColor: '#F8F8F8'
  },
  grid: {
    top: '22%',
    left: '5%',
    bottom: 20,
    right: '5%',
    containLabel: true
  },

  xAxis: {
    type: 'category',
    boundaryGap: false,
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      lineStyle: {
        width: 2,
        type: 'solid',
        color: '#e6e9eb'
      }
    },
    axisLabel: {
      color: '#969CA7'
    }
  },
  yAxis: {
    type: 'value',
    nameGap: 20,
    nameTextStyle: {
      color: '#101D37',
      align: 'middle',
      fontWeight: 'bold'
    },
    axisLine: {
      show: true,
      lineStyle: {
        width: 2,
        color: '#e6e9eb'
      }
    },
    axisLabel: {
      color: '#969CA7',
      showMinLabel: false
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(151,151,151,0.2)',
        type: [5, 3]
      }
    }
    // max: 6000
  },
  series: [
    {
      name: '圆钢',
      data: [],
      type: 'line',
      smooth: true,
      symbolSize: 14,
      showSymbol: false,
      itemStyle: {
        color: '#E64470',
        emphasis: {
          color: '#fff',
          borderWidth: 2
        }
      },
      areaStyle: {
        color: areaColor0
      },
      hoverAnimation: false,
      silent: true
    },
    {
      name: '优特钢',
      data: [],
      type: 'line',
      symbolSize: 14,
      showSymbol: false,
      smooth: true,
      itemStyle: {
        color: '#6958FF',
        emphasis: {
          color: '#fff',
          borderWidth: 2
        }
      },
      areaStyle: {
        color: areaColor1
      },
      hoverAnimation: false,
      silent: true
    }
  ],
  animationDuration: 5 * 1000,
  animationEasing: 'cubicOut'
})
const chartOption2 = reactive({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
      shadowStyle: {
        color: 'rgba(150,150,150,0.1)'
      }
    },
    backgroundColor: 'black',
    textStyle: {
      color: 'white'
    },
    borderWidth: 0,
    valueFormatter: (value) => '¥' + formatNumber(value),
    extraCssText: 'box-shadow:0px 6px 30px 0px rgba(0, 0, 0, 0.25);'
  },
  title: {
    text: '平台成交量走势',
    subtext: '成交量（吨）',
    itemGap: 16,
    subtextStyle: {
      color: '#101D37'
    },
    fontSize: 20,
    color: '#101D37',
    padding: [22, 25]
  },
  legend: {
    itemWidth: 10,
    itemHeight: 10,
    left: 182,
    top: 20,
    padding: [11, 12],
    data: [
      {
        icon: 'circle',
        name: '上周'
      },
      {
        icon: 'circle',
        name: '本周'
      }
    ],
    selectedMode: false,
    borderRadius: [21],
    backgroundColor: '#F8F8F8'
  },
  grid: {
    top: '22%',
    left: '5%',
    bottom: 20,
    right: '5%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    axisTick: {
      show: false
    },
    axisLine: {
      show: true,
      lineStyle: {
        width: 2,
        type: 'solid',
        color: '#e6e9eb'
      }
    },
    axisLabel: {
      color: '#969CA7'
    },
    data: ['周日', '周一', '周二', '周三', '周四', '周五', '周六']
  },
  yAxis: {
    type: 'value',
    nameGap: 20,
    nameTextStyle: {
      color: '#101D37',
      align: 'middle',
      fontWeight: 'bold'
    },
    axisLine: {
      show: true,
      lineStyle: {
        width: 2,
        type: 'solid',
        color: '#e6e9eb'
      }
    },
    axisLabel: {
      color: '#969CA7',
      showMinLabel: false
    },
    splitLine: {
      lineStyle: {
        color: 'rgba(151,151,151,0.2)',
        type: [5, 3]
      }
    }
    // max: 6000
  },
  series: [
    {
      name: '上周',
      data: [],
      type: 'bar',
      barWidth: '16px',
      barGap: 0,
      itemStyle: {
        color: '#3D62F9'
      }
    },
    {
      name: '本周',
      data: [],
      type: 'bar',
      barWidth: '16px',
      itemStyle: {
        color: '#FFC20F'
      }
    }
  ],
  animationDuration: 5 * 1000,
  animationEasing: 'cubicOut'
})
const getSumWeightAsync = async () => {
  const { statusCode, data } = await context.$api.home.getSumWeight()
  if (statusCode == 200) {
    chartOption2.series[0].data = data.lastWeek.map((_) => _.weight)
    chartOption2.series[1].data = data.thisWeek.map((_) => _.weight)
    state.todayWeight = data?.today?.weight
  }
}

const getOrdWeightAsync = async () => {
  const { statusCode, data } = await context.$api.home.getOrdWeight()
  if (statusCode == 200) {
    chartOption.xAxis.data = data.map((_) => formatDate(_.startDate, 'yy/MM/dd'))
    chartOption.series[0].data = data.map((_) => {
      return {
        startDate: formatDate(_.startDate, 'MM/dd'),
        endDate: formatDate(_.endDate, 'MM/dd'),
        value: Math.round(_.weight)
      }
    })
  }
}

const getExPriceAsync = async () => {
  const { statusCode, data } = await context.$api.home.getExPrice()
  if (statusCode == 200) {
    chartOption1.xAxis.data = data?.YG?.map((_) => formatDate(_.dates, 'yy/MM/dd')) ?? []
    chartOption1.series[0].data = data?.YG?.map((_) => _.price) ?? []
    chartOption1.series[1].data = data?.YTG?.map((_) => _.price) ?? []
    let priceArr = []
    if (data.YG) {
      priceArr = [...priceArr, ...data?.YG?.map((_) => _.price)]
    }
    if (data.YTG) {
      priceArr = [...priceArr, ...data?.YTG?.map((_) => _.price)]
    }
    if (priceArr.length) {
      const minPrice = priceArr.reduce(function (a, b) {
        return b > a ? a : b
      })
      chartOption1.yAxis.min = formatInt(minPrice, 2, false)
    }
  }
}

const formatInt = (num, prec = 2, ceil = true) => {
  const len = String(num).length
  if (len <= prec) {
    return num
  }

  const mult = Math.pow(10, prec)
  return ceil ? Math.ceil(num / mult) * mult : Math.floor(num / mult) * mult
}

const { setTimer } = useInterval(60)

const getIntervalTimer = () => {
  setTimer(() => {
    getSumWeightAsync()
    getOrdWeightAsync()
  })
}

const handleVisiable = (e) => {
  const isHidden = e.target.hidden
  if (isHidden === false) {
    getSumWeightAsync()
    getOrdWeightAsync()
    getExPriceAsync()
    getIntervalTimer()
  }
}

onMounted(() => {
  getSumWeightAsync()
  getOrdWeightAsync()
  getExPriceAsync()
  document.addEventListener('visibilitychange', handleVisiable)
  getIntervalTimer()
})
onUnmounted(() => {
  document.removeEventListener('visibilitychange', handleVisiable)
})

expose({
  ...toRefs(state)
})

return { __sfc: true,context, store, state, chartOption, areaColor0, areaColor1, chartOption1, chartOption2, getSumWeightAsync, getOrdWeightAsync, getExPriceAsync, formatInt, setTimer, getIntervalTimer, handleVisiable, formatNumber }
}

}