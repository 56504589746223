
import {
  defineComponent,
  useContext,
  useRoute,
  useStore,
  useAsync,
  provide,
  reactive,
  ref,
  toRefs,
  onMounted,
  watch,
  onUnmounted
} from '@nuxtjs/composition-api'
import flowChart from '@/components/bid/flowChart.vue'
import messageScroll from '@/components/bid/messageScroll.vue'
import sessionDisplay from '@/components/bid/sessionDisplay.vue'
import searchTableList from '@/components/bid/single/searchTableList.vue'
import searchModel from '@/components/spotMall/searchModel.vue'
import resultTab from '@/components/spotMall/resultTab.vue'
import { useSyncTime } from '@/hooks/useSyncTime'
import { bidRefreshSecond } from '@/constant/bid'
import { useLink } from '@/hooks/useLink'
export default defineComponent({
  components: {
    flowChart,
    messageScroll,
    sessionDisplay,
    searchModel,
    searchTableList,
    resultTab
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const store = useStore()
    const route = useRoute()
    const state = reactive({
      sessionData: [],
      timer: null,
      bidNotices: []
    })
    const tableListRef = ref(null)
    const getSessionAsync = async () => {
      tableListRef.value.getListAsync()
      const { data, statusCode } = await context.$api.bid.getSingleBidParam()
      if (statusCode == 200) {
        state.sessionData = data
      }
    }

    const getNoticeList = async () => {
      const { data, statusCode } = await context.$api.home.getNoticeList({
        limit: 5,
        page: 1,
        type: '竞价公告',
        childType: 2
      })
      if (statusCode == 200) {
        const { rows } = data
        state.bidNotices = rows
      }
    }

    const { currentTime } = useSyncTime({
      getListAsync: getSessionAsync,
      freshSecond: bidRefreshSecond,
      isRefreshSecond: true
    })

    provide('syncTime', currentTime)

    watch(
      () => [currentTime.value],
      ([currentTimeNewVal]) => {
        const time = currentTimeNewVal / 1000 / 60 / 30
        if (typeof time === 'number' && time % 1 === 0) {
          setTimeout(() => {
            if (!route.value.query.sr) {
              getSessionAsync()
            } else {
              tableListRef.value.getListAsync()
            }
          }, 1000 * 1)
          getNoticeList()
        }
      }
    )

    watch(
      () => route.value.query.sr,
      (newVal) => {
        if (!newVal) {
          getSessionAsync()
          getNoticeList()
        }
      }
    )

    watch(
      () => route.value.query,
      () => {
        Object.assign(condition, { ...route.value.query })
      }
    )
    const handleVisiable = (e) => {
      const isHidden = e.target.hidden
      if (isHidden === false) {
        if (!route.value.query.sr) {
          getSessionAsync()
          getNoticeList()
        } else {
          tableListRef.value.getListAsync()
        }
      }
    }
    const getCondition = () => {
      return {
        variety: '',
        categoryId: '',
        material: '',
        quality: '',
        deliveryPlace: '',
        repositoryId: '',
        origin: '',
        spec: '',
        widthStart: '',
        widthEnd: '',
        lengthStart: '',
        lengthEnd: '',
        thicknessStart: '',
        thicknessEnd: '',
        measure: '',
        qualityInfo: '',
        sizeInfo: '',
        specialPrice: '',
        invoiceList: [],
        type: '',
        sortName: '',
        sortType: '',
        repositoryName: '',
        materialLike: '',
        designation: '',
        sv: '',
        sk: '',
        sr: '',
        keyWord: '',
        matchList: '',
        listedPriceStart: '',
        listedPriceEnd: '',
        warehouseDateEnd: '',
        warehouseDateStart: '',
        bidStartTime: '',
        bidEndTime: ''
      }
    }
    const condition = reactive(getCondition())
    const sessionRef = ref(null)
    const handleClick = (obj) => {
      sessionRef.value?.scrollToCmtList()
      Object.assign(condition, { ...getCondition() }, { ...obj })
    }
    const handleListChange = (obj) => {
      Object.assign(condition, { ...getCondition() }, { ...obj })
    }

    useAsync(async () => {
      await store.dispatch('spotMall/GET_BIDLITEDATTR', context)
    })

    const handTimeout = () => {
      Object.assign(condition, {
        bidStartTime: '',
        bidEndTime: ''
      })
      getSessionAsync()
    }

    const transParams = (object) => {
      const params = {}
      for (const i in object) {
        if (object[i]) {
          params[i] = object[i]
        }
      }
      return params
    }

    onMounted(() => {
      Object.assign(condition, { ...getCondition() }, { ...route.value.query })
      Object.assign(tableListRef.value.editForm, { ...getCondition() }, { ...route.value.query })
      if (!route.value.query.sr) {
        getSessionAsync()
        getNoticeList()
      } else {
        tableListRef.value.getListAsync()
      }
      document.addEventListener('visibilitychange', handleVisiable)
    })

    onUnmounted(() => {
      document.removeEventListener('visibilitychange', handleVisiable)
    })

    return {
      ...toRefs(state),
      route,
      tableListRef,
      currentTime,
      sessionRef,
      handleClick,
      condition,
      handleListChange,
      handTimeout
    }
  },
  head: {
    title: '单条竞价-鑫智特钢网'
  }
})
