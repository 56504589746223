import { useContext, watch, onMounted, useStore, computed } from '@nuxtjs/composition-api'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'


export default {
  __name: 'fixedMenusBar',
  props: {
  isBsc: {
    type: Boolean,
    default: false
  },
  nowPath: {
    type: String,
    default: ''
  }
},
  setup(__props) {

const props = __props;



const context = useContext()
const store = useStore()
const { userInfo, loginCorpInfo, fixedMenus } = useLoginInfo()
const { push } = useLink()
const { showErrorTip } = useMsgDialog()

const dropHandleCommand = (command) => {
  if (!userInfo.value) {
    return push('/login', { redirect: command.path })
  }
  if (!userInfo.value.loginCorpId) {
    return showErrorTip('', 6)
  }
  if (!command.isAuth) {
    if (loginCorpInfo.value?.memberType == 0 && ((!command.path && command.name == '卖家中心') || command.path.includes('/sellercenter'))) {
      return showErrorTip('', 4)
    } else {
      return showErrorTip('', 1)
    }
  } else {
    push(command.path)
  }
}

const msgCount = computed(() => store.getters.msgCount)

const handleMsg = () => {
  if (!userInfo.value) {
    return push('/login', {})
  } else {
    push('/message')
  }
}

watch(
  () => [userInfo.value.head, userInfo.value],
  ([newHead, newInfo]) => {
    if (newInfo) {
      store.dispatch('home/GET_MSGCOUNT', context)
    }
  }
)

onMounted(() => {
  store.commit('home/SET_MSGCOUNT', 0)
  if (userInfo.value) {
    store.dispatch('home/GET_MSGCOUNT', context)
  }
})

return { __sfc: true,props, context, store, userInfo, loginCorpInfo, fixedMenus, push, showErrorTip, dropHandleCommand, msgCount, handleMsg }
}

}