
import { defineComponent, reactive, toRefs, ref, watchEffect, useContext, computed, inject } from '@nuxtjs/composition-api'
import qualityAssure from '@/components/spotMall/qualityAssure.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { JIN_MAO_ID } from '@/constant'
import { downloadFileByUrl } from '@/utils/tool'

export default defineComponent({
  components: {
    qualityAssure
  },
  props: {
    detailInfo: {
      type: Object,
      defualt: () => {}
    },
    isGoodContent: {
      type: Boolean,
      defualt: true
    }
  },
  setup(props) {
    const context = useContext()
    const { userInfo } = useLoginInfo()
    const $message = inject('$message')
    const { judgeIsLogin } = useMsgDialog()
    const state = reactive({
      warrantyUrl: ''
    })

    const goodsInfo = reactive({
      resourceId: '',
      corpId: '',
      categoryName: '',
      material: '',
      spec: '',
      origin: '',
      qualityInfo: '',
      sizeInfo: '',
      repositoryName: '',
      warehouseDate: '',
      measure: '',
      remark: '',
      warranty: ''
    })
    watchEffect(() => {
      Object.assign(goodsInfo, { ...props.detailInfo })
    })
    const qualityAssureRef = ref(null)
    const showQuality = async () => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (goodsInfo.warranty != 3) {
        return
      }
      const { statusCode, data } = await context.$api.goods.getWarranty({ resourceId: goodsInfo.resourceId })
      if (statusCode == 200) {
        state.warrantyUrl = data ?? ''
        if (/(.pdf)$/i.test(state.warrantyUrl)) {
          downloadFileByUrl(state.warrantyUrl, '质保书.pdf')
        } else {
          qualityAssureRef.value.dialogVisible = true
        }
      }
    }
    const fileTypes = new Map([
      [1, '否'],
      [2, '有(未上传)'],
      [3, '有(已上传)']
    ])

    const getWarrantyState = () => {
      if (!goodsInfo.warranty) {
        return '否'
      } else {
        return fileTypes.get(+goodsInfo.warranty)
      }
    }

    const warrantyDisabled = computed(() => goodsInfo.warranty == 3)

    const openQualityDialog = (url) => {
      state.warrantyUrl = url ?? ''
      qualityAssureRef.value.dialogVisible = true
    }
    return {
      ...toRefs(state),
      goodsInfo,
      qualityAssureRef,
      showQuality,
      fileTypes,
      getWarrantyState,
      warrantyDisabled,
      openQualityDialog
    }
  }
})
