
import { defineComponent, inject, reactive, ref, useContext, toRefs, onMounted, onUnmounted, useStore, computed } from '@nuxtjs/composition-api'
import { Loading } from 'element-ui'
import { numSix, numberRegSix } from '@/utils/validate'
import contractTemp from '@/components/shopCarts/contractTemp.vue'
import payPwdButton from '@/components/shopCarts/payPwdButton.vue'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { orderTypeMap } from '@/constant/orders'
export default defineComponent({
  components: {
    contractTemp,
    payPwdButton
  },
  props: {
    orderInfo: {
      type: Array,
      default: () => []
    },
    params: {
      type: Array,
      default: () => []
    },
    payMoney: {
      type: [Number, String],
      default: 0
    },
    // 已生成的订单若后台改价需要重新签署合同
    isChangePriceOrder: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const context = useContext()
    const store = useStore()
    const $message = inject('$message')
    const { showErrorTip } = useMsgDialog()
    const loading = ref(null)
    const dialogVisible = ref(false)
    const state = reactive({
      pwdFlag: false,
      reSignLoading: false,
      signSuccessDialogVisible: false,
      againDialogVisible: false,
      currentPage: 1,
      contractWrapperHeight: 0
    })
    const penaltyMoney = computed(() => store.getters.transactionParams.entity.penaltyMoney)
    const deliveryTolerance = computed(() => store.getters.transactionParams.entity.deliveryTolerance)
    const taxRate = computed(() => store.getters.transactionParams.entity.taxRate)

    const formRef = ref(null)
    const form = ref({
      password: ''
    })
    const rules = {
      password: numSix('签署密码不能为空')
    }
    const orderOrigin = context.query.value.type == 1 ? 'detail' : 'cart'
    let orderNo = ''
    const signContract = () => {
      formRef.value.validate(async (valid) => {
        if (valid) {
          loading.value = Loading.service({
            lock: false,
            background: 'rgba(0,0,0,0.7)'
          })
          try {
            const apiParams = props.isChangePriceOrder
              ? {
                  contractNo: props.params[0].orderNo,
                  password: form.value.password,
                  penaltyMoney: penaltyMoney.value,
                  tolerance: deliveryTolerance.value,
                  taxRate: taxRate.value
                }
              : {
                  type: orderOrigin,
                  password: form.value.password,
                  list: props.params,
                  penaltyMoney: penaltyMoney.value,
                  tolerance: deliveryTolerance.value,
                  taxRate: taxRate.value,
                  sourceType: 0
                }
            const { statusCode, data, message } = await context.$api[props.isChangePriceOrder ? 'purchase' : 'orderCart'][
              props.isChangePriceOrder ? 'signChangedContract' : 'signContract'
            ](apiParams)
            loading.value.close()
            if (statusCode == 200) {
              if (props.isChangePriceOrder) {
                dialogVisible.value = false
                emit('sign-success')
              } else {
                orderNo = data.join(',')
                state.signSuccessDialogVisible = true
              }
            } else if (statusCode == 30010 || statusCode == 30020) {
              showErrorTip(message)
            } else if (statusCode == 50010) {
              state.againDialogVisible = true
              reSignNo.value = data
            } else if (statusCode == 50020) {
              showErrorTip(message)
              store.dispatch('app/GET_TRANSACTIONPARAMS', context)
            } else if (statusCode == 50030) {
              showErrorTip(message, 9)
            } else {
              $message.error(message)
            }
          } catch (err) {
            loading.value.close()
          }
        }
      })
    }
    const toPay = () => {
      // window.open(`/shop/cashier?orderNo=${orderNo}&orderType=${orderTypeMap.get('现货')}`, '_blank')
      push('/shop/cashier', {
        orderNo,
        orderType: orderTypeMap.get('现货')
      })
    }
    const reSignNo = ref(null)
    const signAgain = async () => {
      state.reSignLoading = true
      const { statusCode, data, message } = await context.$api.orderCart.retrySign({ contractNo: reSignNo.value })
      state.reSignLoading = false
      if (statusCode == 200) {
        if (props.isChangePriceOrder) {
          state.againDialogVisible = false
          dialogVisible.value = false
          emit('sign-success')
        } else {
          state.againDialogVisible = false
          orderNo = data.join(',')
          state.signSuccessDialogVisible = true
        }
      } else if (statusCode == 30010 || statusCode == 30020) {
        showErrorTip(message)
      } else if (statusCode == 50010) {
        $message.error(message)
        state.againDialogVisible = true
        reSignNo.value = data
      } else {
        $message.error(message)
      }
    }
    const openDialog = () => {
      if (formRef.value) {
        formRef.value.resetFields()
        state.pwdFlag = false
        state.currentPage = 1
      }
    }
    const { replace, push } = useLink()
    const changePwd = (value) => {
      if (numberRegSix.test(value)) {
        state.pwdFlag = true
      } else {
        state.pwdFlag = false
      }
    }
    const handleResignClose = (done) => {
      done()

      replace('/buyercenter/purchaseOrder?type=1')
    }

    const scrollEvent = (e) => {
      const scrollItems = document.querySelectorAll('.contract-wrapper')
      for (let i = scrollItems.length - 1; i >= 0; i--) {
        // 判断滚动条滚动距离是否大于当前滚动项可滚动距离
        const judge = e.target.scrollTop + 200 >= scrollItems[i].offsetTop - scrollItems[0].offsetTop
        if (judge) {
          state.currentPage = i + 1
          break
        }
      }
    }

    onMounted(() => {
      state.contractWrapperHeight = document.body.clientHeight - 400
    })

    return {
      dialogVisible,
      formRef,
      form,
      rules,
      signContract,
      openDialog,
      signAgain,
      ...toRefs(state),
      changePwd,
      handleResignClose,
      replace,
      toPay,
      scrollEvent
    }
  }
})
