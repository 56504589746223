
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    text: {
      type: String
    },
    icon: {
      type: String,
      default: ''
    },
    showOverFlowTooltip: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 95
    }
  },
  setup() {}
})
