import { onMounted, reactive, ref, useStore, useContext, inject, computed, watchEffect, nextTick, toRefs } from '@nuxtjs/composition-api'
import { removeAuthInfo, setAuthInfo, removeCorpId, getCorpSelect, setCorpSelect } from '@/utils/auth'
import { requiredInput, password, phone, phoneReg } from '@/utils/validate'
import { useCountDown } from '@/hooks/useCountDown'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { HOT_LINE } from '@/constant'
import SlideCheck from '@/components/SlideCheck.vue'
import choiceCorpDialog from '~/components/layoutComp/choiceCorpDialog'
import { getParams } from '@/utils/sso'


export default {
  __name: 'loginForm',
  emits: 'loginSuccess',
  setup(__props, { expose, emit }) {


const context = useContext()
const $message = inject('$message')
const store = useStore()
const { corps } = useLoginInfo()
const { count, playing, countdown } = useCountDown()
const { $config } = context
const formRef = ref(null)

const loginForm = reactive({
  password: '',
  mobile: '',
  code: '',
  loginMode: 1,
  appId: 'DP'
})
const disabled = computed(() => {
  return !phoneReg.test(loginForm.mobile)
})
const rules = {
  password: password(),
  mobile: phone('请输入手机号码'),
  code: requiredInput('验证码不能为空')
}

onMounted(() => {
  if (process.env.NODE_ENV == 'development') {
    loginForm.mobile = '18205150527'
    loginForm.password = '123456'
    loginForm.loginMode = 2
    selectTab.value = loginForm.loginMode
  }
})
const choiceCorpRef = ref(null)
const state = reactive({
  loading: false,
  dialogVisible: false,
  passwordErrorTime: 0,
  title: '',
  msg: '',
  hotline: HOT_LINE,
  slideDialog: false,
  src: [require('~/assets/img/slideCheck/3.jpg'), require('~/assets/img/slideCheck/1.jpg')],
  loginDisabled: false
})

const corpChange = () => {
  setCorpSelect(context)
  window.location.reload()
}

const getLoginParams = () => {
  if (loginForm.loginMode == 1) {
    const { password, ...params } = loginForm
    return params
  } else {
    const { code, ...params } = loginForm
    return params
  }
}

const logIn = () => {
  formRef.value.validate(async (valid) => {
    if (valid) {
      try {
        state.loading = true
        const { statusCode, message, data } = await context.$api.auth.login(getLoginParams())
        if (statusCode == 200) {
          const { accessToken, refreshToken, userId } = data.TokenInfo
          setAuthInfo(context, {
            token: accessToken,
            refreshToken,
            userId
          })
          removeCorpId(context)
          await store.dispatch('app/GET_USERINFO', context)
          emit('loginSuccess')
          store.dispatch('app/GET_TRANSACTIONPARAMS', context)
          if (corps.value.length > 0) {
            state.loginDisabled = true
            choiceCorpRef.value.corpSelectDialogVisible = true
            nextTick(() => {
              choiceCorpRef.value.selectCorp = corps.value[0]
              choiceCorpRef.value.accountType = '1'
            })
            if (corps.value.every((_) => _.corpStatus == 0 || _.status == 0)) {
              choiceCorpRef.value.showClose = false
            }
          } else {
            // 没有公司，按照普通会员方式登录
            loginAsTourist()
          }
        } else if (statusCode == 302) {
          state.passwordErrorTime = data.passwordError
          if (state.passwordErrorTime == 3) {
            $message.error('手机号/密码输入错误')
            setTimeout(() => {
              state.slideDialog = true
              state.dialogVisible = false
            }, 1000)
          } else if (state.passwordErrorTime == 9) {
            state.dialogVisible = true
            state.title = '密码错误'
          } else if (state.passwordErrorTime == 10) {
            state.dialogVisible = true
            state.title = '密码错误'
            state.msg = '密码错误10次,请30分钟后再试'
          } else {
            $message.error('手机号/密码输入错误')
          }
        } else {
          $message.error(message || '网络异常,请稍后再试')
        }
        state.loading = false
      } catch (error) {
        state.loading = false
        removeAuthInfo(context)
      }
    }
  })
}

const loginAsTourist = () => {
  store.dispatch('app/GET_PERMISSION', context)
}

const selectTab = ref(null)
const changeTab = (value) => {
  selectTab.value = value
  loginForm.loginMode = value
  if (process.env.NODE_ENV != 'development') {
    loginForm.code = ''
    loginForm.password = ''
  }
  formRef.value.clearValidate()
}

watchEffect(() => {
  selectTab.value = loginForm.loginMode
})

const getCode = async () => {
  playing.value = true
  const { statusCode } = await context.$api.sms.getLoginCode({
    mobile: loginForm.mobile,
    appId: 'DP'
  })
  if (statusCode == 200) {
    countdown()
    $message.success('验证码已发送，请注意查收')
  } else {
    playing.value = false
  }
}
const slideSuccess = () => {
  state.slideDialog = false
}

const gotoForgetPwd = () => {
  window.location.href = `${$config.ssoUrl}/forgetPwd${getParams()}`
}

expose({
  ...toRefs(state),
  formRef
})

return { __sfc: true,emit, context, $message, store, corps, count, playing, countdown, $config, formRef, loginForm, disabled, rules, choiceCorpRef, state, corpChange, getLoginParams, logIn, loginAsTourist, selectTab, changeTab, getCode, slideSuccess, gotoForgetPwd, password, SlideCheck, choiceCorpDialog }
}

}