
import { defineComponent, onMounted, reactive, ref, toRefs, useContext } from '@nuxtjs/composition-api'
import tableHeaderButtons from '@/components/notice/tableHeaderButtons.vue'
import queryCondition from '@/components/notice/queryCondition.vue'
import { useExTable } from '@/hooks/useExTable'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { formatDate } from '@/utils/tool'
export default defineComponent({
  components: {
    tableHeaderButtons,
    queryCondition
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const { push, pushBcs } = useLink()
    const state = reactive({
      newsType: []
    })
    const query = reactive({})
    const columns = ref([
      {
        slot: 'content'
      },
      {
        width: '200',
        render: (row) => {
          return <span class="date-color">{formatDate(row.pushTime, 'yyyy年MM月dd日 hh:mm')}</span>
        }
      }
    ])

    const { data, total, getListAsync, search, handleCurrentChange, handleSizeChange } = useExTable({
      query,
      queryApi: context.$api.home.getNewsList,
      limit: 10
    })
    const doSearch = (data) => {
      const order = data.order == 1 ? 'asc' : data.order == 2 ? 'desc' : ''
      const orderField = data.order ? 'PUSH_TIME' : ''
      search({ order, orderField })
    }

    const { showErrorTip } = useMsgDialog()
    const handleRowClick = async (row) => {
      if (row.link) {
        pushBcs(row.link, false)
      } else {
        const { data, statusCode, message } = await context.$api.home.getNewsDeatil({ id: row.id })
        if (statusCode == 200) {
          pushBcs(`/news/${row.id}`, false)
        } else {
          showErrorTip(message)
          getListAsync()
        }
      }
    }
    const getNewsTypeList = async () => {
      const { data, statusCode } = await context.$api.home.getNewsTypeList()
      if (statusCode == 200) {
        state.newsType = data
      }
    }
    onMounted(() => {
      getNewsTypeList()
    })
    return {
      ...toRefs(state),
      columns,
      query,
      data,
      total,
      handleCurrentChange,
      handleSizeChange,
      doSearch,
      search,
      handleRowClick
    }
  },
  head: {
    title: '资讯中心-鑫智特钢网'
  }
})
