export default {
  __name: 'cardTitle',
  props: {
  goodsInfo: {
    type: Object,
    default: () => {}
  }
},
  emits: ['click'],
  setup(__props, { emit }) {

const props = __props;





const doClick = () => {
  emit('click')
}

return { __sfc: true,props, emit, doClick }
}

}