import { computed, inject, onMounted, reactive, ref, toRefs, useContext, useRoute, watch } from '@nuxtjs/composition-api'
import strongSpan from '@/components/homeHeader/components/strongSpan.vue'
import { useLink } from '@/hooks/useLink'


export default {
  __name: 'searchTypeInput',
  setup(__props, { expose }) {

const context = useContext()
const route = useRoute()
const $message = inject('$message')
const { push } = useLink()
const allSearchPath = ref(['/shop', '/bid/single'])
const routePath = computed(() => route.value.path)
const state = reactive({
  searchValue: '',
  searchList: [],
  searchAllList: [],
  historyList: [],
  isDelete: false
})
const searchKeyMap = new Map([
  [0, ['origin', '产地']],
  [1, ['categoryId', '品名']],
  [2, ['material', '材质']],
  [3, ['spec', '规格']]
])

const querySearch = async (queryString, cb) => {
  if (queryString?.length > 0) {
    if (RegExpString(queryString)) {
      await getSearchCondition()
      cb(state.searchList)
    } else {
      cb()
    }
  } else {
    await getHistoryRecord()
    cb(state.historyList)
  }
}

const RegExpString = (str) => {
  const patrn = /^[\u4E00-\u9FA5A-Za-z0-9()-/（）]+$/
  if (patrn.test(str)) {
    return true
  } else {
    return false
  }
}

const handleSelect = (item) => {
  if (item.key != 'deleteAll') {
    if (!state.isDelete) {
      state.searchValue = item.name || item.value
      item.name ? handleSearch(item) : handleHistory(item)
    } else {
      state.isDelete = false
      state.searchValue = ''
    }
  } else {
    const record = []
    window.localStorage.setItem('searchHistory', JSON.stringify(record))
  }
}

const doSearch = (type = 0) => {
  if (state.searchValue) {
    if (RegExpString(state.searchValue)) {
      const searchList = state.searchAllList.reduce((acc, obj) => {
        const key = obj.key
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj.id)
        return acc
      }, {})
      Object.keys(searchList).map((key) => {
        return (searchList[key] = searchList[key].join(','))
      })
      const matchListParams = {
        keyWord: state.searchValue,
        matchList: JSON.stringify(searchList)
      }
      if (type) {
        push(route.value.path, { ...matchListParams, sr: 1 })
      } else {
        push('/shop', { ...matchListParams, sr: 0 })
      }
      saveLocalStorage()
      changeStyle('none', '.el-autocomplete-suggestion')
    } else {
      $message.warning('输入格式不正确')
    }
  }
}
const changeStyle = (status, className) => {
  const dom = document.querySelectorAll(className)
  dom[0].style.display = status
}
watch(
  () => [route.value.query.sv, route.value.query.keyWord],
  ([newVal, newKeyWord]) => {
    if (!newVal && !newKeyWord) state.searchValue = ''
  }
)
const getSearchCondition = async () => {
  const { data, statusCode } = await context.$api.goodsType.getSearchCondition({ keyWord: state.searchValue })
  if (statusCode == 200) {
    const { categoryNameList = [], categoryIdList = [], materialList = [], originList = [] } = data
    state.searchList = []
    state.searchAllList = []
    categoryNameList?.forEach((name, index) => {
      state.searchAllList.push({
        name,
        id: categoryIdList[index],
        key: searchKeyMap.get(1)[0],
        tipLabel: searchKeyMap.get(1)[1]
      })
    })
    materialList?.forEach((res) => {
      state.searchAllList.push({
        name: res,
        id: res,
        key: searchKeyMap.get(2)[0],
        tipLabel: searchKeyMap.get(2)[1]
      })
    })
    originList?.forEach((res) => {
      state.searchAllList.push({
        name: res,
        id: res,
        key: searchKeyMap.get(0)[0],
        tipLabel: searchKeyMap.get(0)[1]
      })
    })
    state.searchList = state.searchAllList.slice(0, 10)
  }
}

const handleSearch = (list) => {
  const key = list.key
  const param = {
    sv: list.name,
    sk: list.key,
    sr: 0
  }
  param[key] = list.id
  const matchList = {}
  matchList[key] = list.id
  if (allSearchPath.value.includes(routePath.value)) {
    push(route.value.path, { ...param, matchList: JSON.stringify(matchList), sr: 1 })
  } else {
    push('/shop', { ...param, matchList: JSON.stringify(matchList) })
  }
  saveLocalStorage()
}

const saveLocalStorage = () => {
  if (process.client) {
    const record = state.historyList?.filter((_) => !_.key)
    if (record?.length > 9) {
      record.pop()
    }
    const index = record.findIndex((_) => _.value == state.searchValue)
    if (index != -1) {
      record.splice(index, 1)
    }
    record.unshift({ value: state.searchValue })
    window.localStorage.setItem('searchHistory', JSON.stringify(record))
  }
}
const getHistoryRecord = () => {
  if (process.client) {
    const origin = window.localStorage.getItem('searchHistory')
    state.historyList = origin ? JSON.parse(origin) ?? [] : []
    if (state.historyList?.length) {
      state.historyList.push({ key: 'deleteAll' })
    }
  }
}
const handleHistory = async (list) => {
  await getSearchCondition()
  handleKeyupSearch()
}
const handleDeleteHistory = (list) => {
  state.isDelete = true
  const record = state.historyList?.filter((_) => !_.key)
  const index = record.findIndex((_) => _.value == list.value)
  if (index != -1) {
    record.splice(index, 1)
  }
  state.historyList = record
  window.localStorage.setItem('searchHistory', JSON.stringify(record))
}

const handleKeyupSearch = () => {
  if (allSearchPath.value.includes(routePath.value)) {
    doSearch('1')
  } else {
    doSearch()
  }
}

onMounted(() => {
  if (route.value.query.sv || route.value.query.keyWord) {
    state.searchValue = route.value.query.sv || route.value.query.keyWord
  }
})

expose({
  ...toRefs(state)
})

return { __sfc: true,context, route, $message, push, allSearchPath, routePath, state, searchKeyMap, querySearch, RegExpString, handleSelect, doSearch, changeStyle, getSearchCondition, handleSearch, saveLocalStorage, getHistoryRecord, handleHistory, handleDeleteHistory, handleKeyupSearch, strongSpan }
}

}