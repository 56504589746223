
import { defineComponent, reactive, ref, useStore, toRefs, useRoute, useContext, onMounted, watch } from '@nuxtjs/composition-api'
import tableList from './components/tableList'
import searchModel from '@/components/spotMall/searchModel.vue'
import tableSearchBar from '@/components/spotMall/tableSearchBar.vue'
import resultTab from '@/components/spotMall/resultTab.vue'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useMsgDialog } from '@/hooks/useMsgDialog'
import { formatNumber } from '@/utils/tool'
export default defineComponent({
  name: '',
  components: {
    searchModel,
    tableSearchBar,
    resultTab,
    tableList
  },
  layout: 'home',
  setup() {
    const context = useContext()
    const store = useStore()
    const route = useRoute()
    const { userInfo, loginCorpId, isCartAuth } = useLoginInfo()
    const { judgeIsLogin, judgeIsCompany, judgeIsAuth } = useMsgDialog()
    const state = reactive({
      isBatch: false,
      totalWeight: 0,
      totalCount: 0
    })
    // 批量加购
    const tableListRef = ref(null)
    const handleBatchShop = (type) => {
      if (!userInfo.value) {
        judgeIsLogin()
        return
      }
      if (!loginCorpId.value) {
        judgeIsCompany()
        return
      }
      if (!isCartAuth.value) {
        judgeIsAuth()
        return
      }
      state.isBatch = type
      if (state.isBatch) {
        tableListRef.value.clearCheckState()
        tableListRef.value.getSearchDetailsListAsync()
      } else {
        tableListRef.value.getGoodsLists(state.isBatch)
      }
    }

    const handleClick = (obj) => {
      Object.assign(condition, { ...getCondition() }, { ...obj })
      state.isBatch = false
    }

    const getSearchData = async (isInint = false) => {
      const { material, variety, origin, repositoryId, lengthEnd, lengthStart, thicknessEnd, thicknessStart, widthEnd, widthStart, spec, purpose } =
        condition
      await store.dispatch('spotMall/GET_LITEDATTR', {
        context,
        params: {
          lengthEnd,
          lengthStart,
          thicknessEnd,
          thicknessStart,
          widthEnd,
          widthStart,
          material,
          origin,
          repositoryId,
          variety,
          spec,
          purpose
        },
        isInint
      })
    }

    let source = context.$axios.CancelToken.source()
    const getStatisticsAsync = async (query) => {
      source && source.cancel()
      source = context.$axios.CancelToken.source()
      try {
        const { data, statusCode } = await context.$api.goods.getStatistics(query, source.token)
        if (statusCode == 200) {
          state.totalWeight = data?.weight ?? 0
          state.totalCount = data?.count ?? 0
        }
      } catch {
        console.log('CancelToken-error')
      }
    }
    const getCondition = () => {
      return {
        variety: '',
        categoryId: '',
        material: '',
        quality: '',
        deliveryPlace: '',
        repositoryId: '',
        origin: '',
        spec: '',
        widthStart: '',
        widthEnd: '',
        lengthStart: '',
        lengthEnd: '',
        thicknessStart: '',
        thicknessEnd: '',
        measure: '',
        qualityInfo: '',
        sizeInfo: '',
        specialPrice: '',
        invoiceList: [],
        type: '',
        sortName: '',
        sortType: '',
        repositoryName: '',
        materialLike: '',
        designation: '',
        sv: '',
        sk: '',
        sr: '',
        keyWord: '',
        matchList: '',
        listedPriceStart: '',
        listedPriceEnd: '',
        warehouseDateEnd: '',
        warehouseDateStart: '',
        purpose: ''
      }
    }

    const condition = reactive(getCondition())
    watch(
      () => [route.value.query, route.value.query.sv],
      ([newValue, newValue1]) => {
        Object.assign(condition, { ...getCondition() }, { ...route.value.query })
        if (newValue1) {
          getSearchData()
        }
      }
    )

    onMounted(async () => {
      Object.assign(condition, { ...getCondition() }, { ...route.value.query })
      await store.dispatch('spotMall/GET_LITEDATTR', {
        context,
        params: {},
        isInint: true
      })
      getSearchData()
    })
    return {
      ...toRefs(state),
      userInfo,
      judgeIsLogin,
      handleBatchShop,
      loginCorpId,
      route,
      tableListRef,
      handleClick,
      getStatisticsAsync,
      formatNumber,
      condition,
      getSearchData
    }
  },
  head: {
    title: '现货商城-鑫智特钢网'
  }
})
