import { useContext, useRoute, ref, onMounted, inject, nextTick, reactive, toRefs, watch } from '@nuxtjs/composition-api'
import sectionTitle from './publish/sectionTitle.vue'
import Basic from './publish/basic.vue'
import Demand from './publish/demand.vue'
import { scrollToError } from '@/utils/scrollToError'
import { useLoginInfo } from '@/hooks/useLoginInfo'
import { useLink } from '@/hooks/useLink'
import { useMsgDialog } from '@/hooks/useMsgDialog'


export default {
  __name: 'c2mPublish',
  setup(__props, { expose }) {

const context = useContext()
const $message = inject('$message')
const basicRef = ref(null)
const demandRef = ref(null)
const route = useRoute()
const { push } = useLink()

const state = reactive({
  editable: false,
  demandId: ''
})

const { userInfo, loginCorpId, c2mAuth } = useLoginInfo()
const { judgeIsLogin, judgeIsCompany } = useMsgDialog()
const handleEdit = () => {
  if (!userInfo.value) {
    judgeIsLogin()
    return
  }
  // if (!loginCorpId.value) {
  //   judgeIsCompany()
  //   return
  // }
  state.editable = true
}

const getParams = () => {
  const {
    cuttingNo,
    provideMaterials,
    sampleFileList,
    machiningDemandInfo,
    materialFileList,
    materialInfo,
    requiredCompletionDate,
    materialRequirements,
    originRequirements,
    areaName
  } = demandRef.value.editForm
  const params = {
    ...basicRef.value.editForm,
    provideMaterials,
    cuttingNo,
    sampleFileList: sampleFileList.map((_) => _.uuid),
    machiningDemandInfo,
    requiredCompletionDate,
    materialRequirements,
    originRequirements,
    areaName: areaName.join(',')
  }
  if (provideMaterials) {
    Object.assign(params, { materialFileList: materialFileList.map((_) => _.uuid), materialInfo })
  }
  return params
}

const submit = () => {
  if (!userInfo.value) {
    judgeIsLogin()
    return
  }
  // if (!loginCorpId.value) {
  //   judgeIsCompany()
  // }
  Promise.all([basicRef.value.validate(), demandRef.value.validate()]).then(async (res) => {
    if (res) {
      const { statusCode, data } = !state.demandId
        ? await context.$api.c2m.createDemandInfo(getParams())
        : await context.$api.c2m.updateDemandInfo({ ...getParams(), demandNo: info.value.demandNo })
      if (statusCode === 200) {
        confirmReleaseAsync(data.id)
      }
    } else {
      nextTick(() => {
        scrollToError()
      })
    }
  })
}

const confirmReleaseAsync = async (demandId) => {
  const { statusCode } = await context.$api.c2m.confirmRelease({ demandId })
  if (statusCode == 200) {
    $message.success('发布成功')
    demandRef.value.resetFields()
    state.editable = false
    if (c2mAuth.value) {
      push('/buyercenter/c2m')
    }
  }
}

watch(
  () => route.value.query,
  (newV) => {
    if (JSON.stringify(newV) == '{}') {
      basicRef.value.resetFields()
      demandRef.value.resetFields()
      state.editable = false
    }
  },
  {
    deep: true
  }
)

const info = ref({})
const getDemandInfoAsync = async (demandId) => {
  const { statusCode, data } = await context.$api.c2m.getDemandInfo({ demandId })
  if (statusCode === 200) {
    info.value = data || {}
    info.value.provideMaterials = +info.value.provideMaterials
    info.value.cuttingNo = +info.value.cuttingNo
    info.value.sampleFileList =
      data?.sampleFileList?.map((list) => {
        return {
          ...list,
          url: list.fileUrl,
          uuid: list.fileUuid,
          uid: list.fileUuid,
          name: list.fileName
        }
      }) || []
    info.value.materialFileList =
      data?.materialFileList?.map((list) => {
        return {
          ...list,
          url: list.fileUrl,
          uuid: list.fileUuid,
          uid: list.fileUuid,
          name: list.fileName
        }
      }) || []
  }
}

onMounted(() => {
  info.value = {
    contactPerson: userInfo.value.optUserName,
    contactMobile: userInfo.value.mobile
  }
  if (route.value.query.id) {
    state.demandId = route.value.query.id
    getDemandInfoAsync(state.demandId)
  }
})

expose({
  ...toRefs(state)
})

return { __sfc: true,context, $message, basicRef, demandRef, route, push, state, userInfo, loginCorpId, c2mAuth, judgeIsLogin, judgeIsCompany, handleEdit, getParams, submit, confirmReleaseAsync, info, getDemandInfoAsync, sectionTitle, Basic, Demand }
}

}