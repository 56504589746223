import { render, staticRenderFns } from "./shopItemHead.vue?vue&type=template&id=13745d70"
import script from "./shopItemHead.vue?vue&type=script&lang=js"
export * from "./shopItemHead.vue?vue&type=script&lang=js"
import style0 from "./shopItemHead.vue?vue&type=style&index=0&id=13745d70&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageBox: require('/usr/src/app/components/basic/imageBox.vue').default})
