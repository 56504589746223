import { ref } from '@nuxtjs/composition-api' // toRefs


export default {
  __name: 'company',
  setup(__props) {

const info = ref([
  { name: '公司名称: ', content: '安徽宝景供应链管理有限公司' },
  { name: '纳税人识别号：', content: '91340500MA8PRF9MXJ' },
  { name: '开户行：', content: '中信银行股份有限公司马鞍山湖北路支行' },
  { name: '账号：', content: '8112301012700888788' },
  { name: '行号：', content: '302365051615' },
  { name: '地址：', content: '安徽省马鞍山市郑蒲港新区中飞大道277号孵化园6号楼4楼425号' },
  { name: '电话：', content: '025-57080020' }
])

return { __sfc: true,info }
}

}